import { React, View, CenterWrapper, StyleSheet } from 'lib'
import { Theme } from 'app'
import { PageTitle, ReviewsCarousel } from 'components'
import { reviews } from '../_app/usersReviews'

interface ReviewProps {
  bgColor?: 'light' | 'dark'
}

const Reviews: React.FC<ReviewProps> = ({ bgColor = 'dark' }) => {
  const isSmall = Theme.hooks.down('mid')
  return (
    <CenterWrapper style={[styles.wrapper, bgColor === 'dark' ? { backgroundColor: Theme.colors.greyscale1 } : { backgroundColor: Theme.colors.greyscale6 }]} contentContainerStyle={styles.innerWrapper} fullWidth={isSmall}>
      <PageTitle location='RATING & REVIEWS' title='What Our Users Are Saying' titleColor={bgColor === 'dark' ? 'white' : 'black'} style={styles.pageTitle}/>
      <View style={styles.carousel}>
        <ReviewsCarousel color={ bgColor === 'dark' ? 'white' : 'black' } reviews={reviews}/>
      </View>
    </CenterWrapper>
  )
}

const styles = StyleSheet.createComponentStyleSheet({
  wrapper: {

    position: 'relative',
  },
  innerWrapper: {
    marginTop: Theme.spacing(12),
    marginBottom: Theme.spacing(16),
    display: 'flex',
    flexDirection: 'column',
    gap: Theme.spacing(8),
    ...Theme.justifyCenter,
    ...Theme.alignCenter,
  },
  carousel: {
    width: '100%',
    cursor: 'grab',
    paddingTop: Theme.spacing(2),
    paddingBottom: Theme.spacing(2),
  },
  pageTitle: {
    alignItems: 'center',
    textAlign: 'center',
    [Theme.media.down('mid')]: {
      paddingLeft: Theme.spacing(2),
      paddingRight: Theme.spacing(2),
    },
  },

})

export default Reviews
