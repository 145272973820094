import { Image, React, StyleSheet } from 'lib'
import { Theme } from 'app'

import wave from 'assets/Wave.png'

const WaveSeparator = () => (
  <div style={styles.container}>
    <Image
      source={wave}
      style={styles.wave}
    />
  </div>
)

const styles = StyleSheet.createComponentStyleSheet({
  container: {
    ...Theme.flex,
    ...Theme.fullWidth,
    backgroundColor: Theme.colors.greyscale1,
  },
  wave: {
    width: '100%',
  },
})

export default WaveSeparator
