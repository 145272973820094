export interface ReviewProps {
  name: string
  title: string
  content: string
  rating: number
}

export const reviews: ReviewProps[] = [
  {
    name: 'slinkyn',
    title: 'Gives me peace of mind',
    content: 'This app is fantastic. Not only is the installation and setup super simple, but the interaction through the app and the various functions within it are really well-built and very intuitive. It’s really quick, I love the automated features and frankly, I feel a lot safer using it. We need more apps like this!',
    rating: 5,
  },
  {
    name: 'bl0cky',
    title: 'Perfect',
    content: 'Becon is a gift that keeps on giving. Now added with an SOS button quick in hand. Does exactly what it says it will do as well. All this for free showing that the developers really do care about the safety of those who download the app. I’ve recommended this app to a few friends too and they love it just as much as me. I don’t go anywhere without putting this app on to walk with me virtually!',
    rating: 5,
  },
  {
    name: 'DeeYTee',
    title: 'Amazing app to be safe and FEEL safe',
    content: 'Can’t believe this wasn’t around earlier. Simple interface and functionality.... Would highly recommend',
    rating: 5,
  },
  {
    name: 'Elmer',
    title: 'Finally an app that works for me',
    content: 'I do lots of walking in the morning, usually on my own and it’s just great to have something like this to give me an extra boost of confidence. I’ve tried other apps and they are clunky, Becon is seamless. 5 stars',
    rating: 5,
  },
  {
    name: 'AS_14_06_88',
    title: 'This app is a god send',
    content: 'It’s taken all the anxiety out of my walks home. I hated walking even short trips from the station especially in the dark but I feel super protected now. And it was so easy to set up & then it just ticks away in the background without you having to worry. Really couldn’t recommend more if you’re feeling conscious of walking alone.',
    rating: 5,
  },
  {
    name: 'ig02reviews',
    title: 'Highly recommend',
    content: 'Great app. Makes me and my loved ones feel safer when walking alone. Easy to use. Couldn’t recommend more!',
    rating: 5,
  },
  {
    name: 'Lazylai',
    title: 'At last!',
    content: 'This app is something that should’ve come existed a long time ago. I wander and travel solo a lot so now friends and family can know that I’m safe. It’s also user-friendly which is ideal!',
    rating: 5,
  },
  {
    name: 'TSMP_0017',
    title: 'What a phenomenal idea!',
    content: 'I can’t believe it’s taken this long for something like this to appear! Seems like such an obvious winner. But thankfully it’s been so wonderfully designed. Well done to the team behind this and thank you for producing such a reassuring app!',
    rating: 5,
  },
]
