import React from 'react'
import { onMount } from 'lib'
import { useLocation } from 'react-use'

import Landing from './Landing'
import Triggers from './Triggers'
import Featured from './Featured'
import HowBeconWorks from './HowBeconWorks'
import Reviews from '../../components/Reviews'
import Download from './Download'
import { BeconPlusStore } from 'components'
import WaveSeparator from '../../components/WaveSeparator'
import BeconInPress from './BeconInPress'

function Homepage() {
  const { hash } = useLocation()

  onMount(() => {
    if (hash === '#how-it-works') {
      Tools.scrollTo('#how-it-works')
    }
  })

  return (
    <>
      <Landing/>
      <Featured/>
      <WaveSeparator/>
      <BeconPlusStore image='beconPlus' imageSize='large'/>
      <Triggers/>
      <HowBeconWorks/>
      <BeconInPress/>
      <Reviews bgColor='dark'/>
      <Download/>
    </>
  )
}

export default Homepage
